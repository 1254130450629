define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-card", ["exports", "discourse-i18n", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-model", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-follow-btn", "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-handle", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _discourseI18n, _activityPubActorModel, _activityPubFollowBtn, _activityPubHandle, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubActorCard = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="activity-pub-actor-card">
      <div class="activity-pub-actor-card-top">
        <ActivityPubHandle @actor={{@actor}} @hideLink={{true}} />
        <ActivityPubFollowBtn @actor={{@actor}} @type="follow" />
      </div>
      <div class="activity-pub-actor-card-bottom">
        <ActivityPubActorModel @actor={{@actor}} />
        <div class="follower-count activity-pub-actor-metadata">
          {{i18n
            "discourse_activity_pub.about.actor.follower_count"
            count=@actor.follower_count
          }}
        </div>
      </div>
    </div>
  
  */
  {
    "id": "IvXQnyuU",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"activity-pub-actor-card\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-card-top\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@actor\",\"@hideLink\"],[[30,1],true]],null],[1,\"\\n      \"],[8,[32,1],null,[[\"@actor\",\"@type\"],[[30,1],\"follow\"]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-actor-card-bottom\"],[12],[1,\"\\n      \"],[8,[32,2],null,[[\"@actor\"],[[30,1]]],null],[1,\"\\n      \"],[10,0],[14,0,\"follower-count activity-pub-actor-metadata\"],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"discourse_activity_pub.about.actor.follower_count\"],[[\"count\"],[[30,1,[\"follower_count\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@actor\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-actor-card.js",
    "scope": () => [_activityPubHandle.default, _activityPubFollowBtn.default, _activityPubActorModel.default, _discourseI18n.i18n],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "activity-pub-actor-card:ActivityPubActorCard"));
  var _default = _exports.default = ActivityPubActorCard;
});